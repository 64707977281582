import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Subscription from "@narative/gatsby-theme-novela/src/components/Subscription";

const Subscribe = () => {
  return (
    <Layout>
      <SEO
        title="Subscribe to the Front-end Fresh Newsletter"
        description="Receive front-end engineering tips, tutorials, and projects on a weekly basis!"
      />
      <Section>
        <div style={{ marginTop: "100px" }}>
          <Subscription />
        </div>
      </Section>
    </Layout>
  );
};

export default Subscribe;
